import styled from 'styled-components';

export const Container = styled.div`
  > p {
    line-height: 1.4;
    padding: 0 14px;
    margin: 0 0 10px;
  }

  img,
  video {
    width: 100%;
    margin-bottom: 10px;
  }

  iframe {
    width: 100%;
    height: 90vw;
  }
`;

export const Promo = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-transform: lowercase;
  padding: 14px;
  margin: 0;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 8px 14px;
  color: var(--base-theme-color);
`;
