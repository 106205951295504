import styled from 'styled-components';

type Prop = {
  isOpen?: boolean;
}

export const Container = styled.div<Prop>`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  background-color: var(--light-bg-color);
  z-index: 10;
  border-radius: 14px 14px 0 0;
  transition: transform 0.3s ease;
  transform: translateY(${(props) => props.isOpen ? '0' : '60px'});

  .rhap_additional-controls,
  .rhap_volume-controls {
    display: none;
  }

  .rhap_controls-section {
    flex: 1;
  }

  .rhap_container {
    border-radius: 14px 14px 0 0;
  }
`;

export const Button = styled.button<Prop>`
  position: fixed;
  right: 70px;
  top: 14px;
  background-color: ${(props) => props.isOpen ? 'transparent' : 'var(--light-bg-color)'};
  color: #2a2a2a;
  padding: 5px;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  z-index: 12;

  svg {
    ${(props) => props.isOpen && 'fill: #fff;'};
  }
`
