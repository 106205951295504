import styled from 'styled-components';

type Props = {
  isOpen?: boolean;
  stepNumber?: number;
  invert?: boolean;
}

export const Container = styled.div<Props>`
  padding: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--main-bg-color);
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => !props.isOpen && 'display: none;'};
`;

export const Title = styled.div`
  font-weight: 700;
  margin: auto 0 14px;
  text-transform: lowercase;

  p {
    text-align: center;
  }

  span {
    font-weight: 700;
    font-size: 30px;
  }
`

export const Button = styled.button`
  font-weight: 700;
  color: var(--main-text-color);
  font-size: 16px;
  border-radius: 12px;
  padding: 14px;
  border: var(--neon-theme-yellow-color) 3px solid;
  background-color: transparent;
  box-shadow: var(--neon-theme-yellow-shadow);
  margin-top: auto;
  margin-bottom: 40px;
`

export const ShadowBox = styled.div<Props>`
  position: absolute;
  top: -70px;
  left: 0;
  height: 70px;
  width: 100%;
  background-color: var(--main-bg-color);
  transition: transform 0.3s 0.3s ease;
  transform: translateX(${props => props.stepNumber === 1 ? '170px' : props.stepNumber === 2 ? '-70px' : '0'});
`

export const Message = styled.div`
  position: relative;
`

export const ArrowLeft = styled.div<Props>`
  position: absolute;
  transform: translate(-50%, -100%);
  width: calc(90vw - 50px);
  height: calc(50vh - 120px);
  top: -10px;
  left: 50%;

  svg {
    stroke: var(--neon-theme-yellow-color);
    filter: drop-shadow( 6px 8px 13px rgba(255,255,255,1));
    width: 100%;
    height: 100%;
    ${props => props.invert ? 'transform: scaleX(-1);' : ''}
  }
`
