import React from 'react';
import QrReader from 'react-qr-reader';
import { Button, Container, Title } from './style';
import { service } from '../../services/events.service';
import events from '../../constants/events';

type Props = {
  history: any;
}

type State = {
  qrData: string | null;
  isOpen: boolean;
}

export class QRReader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      qrData: '',
      isOpen: false
    }
  }

  openReader = () => {
    service.events(this.state.isOpen ? events.CLICK_CLOSE_READER : events.CLICK_OPEN_READER);
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handleScan = (data: string | null) => {
    if (data) {
      this.setState({
        qrData: data
      })
      service.events(events.SCAN_QR_READ);
      this.props.history.push(`/projects/${data.split('/').pop()}`);
      this.setState({
        isOpen: false
      })
    }
  }

  handleError = (err: string) => {
    service.events(events.SCAN_QR_ERROR);
    console.error(err)
  }

  render() {
    const { isOpen } = this.state;

    return (
      <div>
        <Button onClick={this.openReader} isOpen={isOpen} aria-label="Считать QR">
          {isOpen
            ? (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"/>
              </svg>
            )
            : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 6H8V8H6V6Z" fill="#2E3A59"/>
                <path d="M3 11V3H11V11H3ZM5 5V9H9V5H5Z" fill="#2E3A59"/>
                <path d="M13 13H16V15H13V13Z" fill="#2E3A59"/>
                <path d="M16 15H18V13H21V16H18V17H19V18H21V21H19V19H17V21H13V17H15V19H16V15Z" fill="#2E3A59"/>
                <path d="M18 6H16V8H18V6Z" fill="#2E3A59"/>
                <path d="M13 3V11H21V3H13ZM19 5V9H15V5H19Z" fill="#2E3A59"/>
                <path d="M6 16H8V18H6V16Z" fill="#2E3A59"/>
                <path d="M3 21V13H11V21H3ZM5 15V19H9V15H5Z" fill="#2E3A59"/>
              </svg>
            )}
        </Button>

        {isOpen && (
          <Container>
            <Title>Поймай QR</Title>
            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '100%' }}
            />
          </Container>)}
      </div>
    );
  }
}
