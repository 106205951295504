export default {
  START: 'START',
  CLICK_OPEN_READER: 'CLICK_OPEN_READER',
  CLICK_CLOSE_READER: 'CLICK_CLOSE_READER',
  SCAN_QR_READ: 'SCAN_QR_READ',
  SCAN_QR_ERROR: 'SCAN_QR_ERROR',
  CLICK_ACCEPT_TUTORIAL: 'CLICK_ACCEPT_TUTORIAL',
  SCAN_NFC_READ: 'SCAN_NFC_READ',
  SCAN_NFC_ERROR: 'SCAN_NFC_ERROR',
  SCAN_NFC_FAILED: 'SCAN_NFC_FAILED',
  CLICK_DETAILS_SWIPER: 'CLICK_DETAILS_SWIPER',
  SWIPE_SLIDE_CHANGE: 'SWIPE_SLIDE_CHANGE',
  CLICK_DETAILS_CARD: 'CLICK_DETAILS_CARD',
  CLICK_AUDIO_BUTTON_PLAY: 'CLICK_AUDIO_BUTTON_PLAY',
  CLICK_AUDIO_BUTTON_PAUSE: 'CLICK_AUDIO_BUTTON_PAUSE',
  CLICK_LIKE_BUTTON: 'CLICK_LIKE_BUTTON'
}
