import styled from 'styled-components';

type Props = {
  backgroundImage?: string;
}

export const List = styled.div`
  padding: 14px;
  display: flex;
  flex-wrap: wrap;
`;

export const Card = styled.div<Props>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  height: 68vw;
  margin-bottom: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/img/projects/${(props) => props.backgroundImage}');
  width: 60%;
  margin-right: 10px;
  filter: grayscale(1) brightness(0.5);
  animation: 14s linear 0s infinite light;
  will-change: auto;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    left: 0;
    background-color: var(--neon-theme-yellow-color);
    box-shadow: 3px 0 15px 2px var(--neon-theme-yellow-shadow-color);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
    box-shadow: -3px 0 15px 2px var(--neon-theme-yellow-shadow-color);
  }

  &:nth-child(10n + 1),
  &:nth-child(10n + 2),
  &:nth-child(10n + 5) {
    animation-duration: 15s;

    &::before,
    &::after {
      width: 3px;
      height: 100%;
      box-shadow: 3px 0 15px 2px var(--neon-theme-yellow-shadow-color);
    }

    &::before {
      top: 0;
      right: 0;
      left: auto;
      box-shadow: -3px 0 15px 2px var(--neon-theme-yellow-shadow-color);
    }
  }

  &:nth-child(10n + 1) {
    animation-duration: 7s;
  }

  &:nth-child(10n + 2),
  &:nth-child(10n + 5) {
    width: calc(40% - 10px);
    margin-right: 0;
  }

  &:nth-child(10n + 5) {
    margin-right: 10px;
  }

  &:nth-child(10n + 3) {
    width: 100%;
    height: 45vw;
    margin-right: 0;
    animation-duration: 17s;
  }

  &:nth-child(10n + 4),
  &:nth-child(10n + 7),
  &:nth-child(10n + 10) {
    width: 100%;
    height: 20vw;
    margin-right: 0;
    animation-duration: 23s;
  }

  &:nth-child(10n + 6) {
    margin-right: 0;
    animation-duration: 14s;
  }

  &:nth-child(10n + 8) {
    width: calc(50% - 5px);
    animation-duration: 27s;
  }

  &:nth-child(10n + 9) {
    width: calc(50% - 5px);
    margin-right: 0;
    animation-duration: 19s;
  }

  @keyframes light {
    0% {
      filter: grayscale(1) brightness(0.5);
    }

    41% {
      filter: grayscale(1) brightness(0.5);
    }

    42% {
      filter: grayscale(0);
    }

    99% {
      filter:  grayscale(0);
    }

    100% {
      filter: grayscale(1) brightness(0.5);
    }
  }
`

export const Title = styled.div`
  position: relative;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 15%;
    left: 15px;
    display: block;
    height: 70%;
    background-color: rgba(var(--dark-bg-color-rgb),0.8);
    width: calc(100% - 30px);
    z-index: -1;
  }
`

export const Icon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  padding: 8px;
  z-index: 1;

  svg {
    width: 100%;
    height: 100%;
    fill: var(--main-text-color);
  }
`
