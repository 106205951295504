import React from 'react';
import { Container, Promo, Title, Text } from './style';
import { data, types } from '../../data/config';
import { ContentTypes } from '../../constants';
import { AudioButton } from '../../components/AudioButton/view';
import { LikeButton } from '../../components/LikeButton/view';
import { AreaType } from '../../types';

type Props = {
  history: any;
  match: any;
}

export class Page extends React.Component<Props> {
  openCard = (link: string) => {
    this.props.history.push(link);
  }

  render() {
    const project = data.find(el => el.id === this.props.match.params.id);
    if (!project) return (<Container>Этого проекта еще нет, но именно вы можете его создать!</Container>);

    return (
      <Container>
        <Promo>
          <img src={`/img/projects/${project.poster.name}`} alt={project.poster.alt}/>
        </Promo>
        <Title>{project.title}</Title>
        {project.content.map(item => (
          item.type === ContentTypes.PARAGRAPH
            ? (<p key={item.text.slice(0, 10)}>{item.text}</p>)
            : item.type === ContentTypes.IMG
              ? (<img key={item.text.slice(0, 10)} src={`/img/projects/${item.text.slice(0, 10)}`} alt={item.text.slice(0, 10)}/>)
              : item.type === ContentTypes.VIDEO
                ? (<video key={item.text} src={item.src} controls crossOrigin="use-credentials"/>)
                : (<iframe width="100%" key={item.text} src={item.src} title="YouTube video player" frameBorder="0" allowFullScreen/>)
        ))}
        <Text>автор:<br/>{project.author}</Text>
        <Text>направление:<br/>{types[project.type as AreaType].type}</Text>
        <Text>куратор:<br/>{types[project.type as AreaType].name}</Text>

        {project.audio && <AudioButton src={project.audio} />}
        <LikeButton pageId={project.id}/>
      </Container>
    );
  }
}
