import styled from 'styled-components';

type Prop = {
  isOpen?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Message = styled.p<Prop>`
  margin: 0 4px 0 0;
  color: var(--neon-theme-yellow-color);
  animation: 3s ease 3s flade;
  transition: transform 2s, opacity 1s;
  opacity: 0;
  transform: translateX(20px);

  ${(props) => props.isOpen && 'transform: translateX(0);opacity: 1;'}
`

export const Button = styled.button<Prop>`
  background-color: transparent;
  color: #2a2a2a;
  padding: 5px;
  border-radius: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 14px 4px 0;

  svg {
    ${(props) => props.isOpen ? 'fill: #fff;' : 'stroke: #fff;stroke-width: 2px;'};
  }
`
