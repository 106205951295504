import React, { LegacyRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import H5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Button, Container } from './style';
import { service } from '../../services/events.service';
import events from '../../constants/events';

type Props = {
  src: string;
}

type State = {
  qrData: string | null;
  isPlay: boolean;
}

export class AudioButton extends React.Component<Props, State> {
  private readonly playerRef: LegacyRef<H5AudioPlayer> | undefined;
  constructor(props: Props) {
    super(props);
    this.playerRef = React.createRef();

    this.state = {
      qrData: '',
      isPlay: false
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevProps.src !== this.props.src) {
      this.setState({
        isPlay: false
      })

      if (!this.playerRef
        || typeof this.playerRef === 'string'
        || typeof this.playerRef === 'function'
        || this.playerRef.current === null) return;

      const audioElement = this.playerRef.current.audio.current;
      if (audioElement === null) return;

      audioElement.pause();
    }
  }

  openPLayer = () => {
    const currentIsPlay = this.state.isPlay;
    this.setState({
      isPlay: !this.state.isPlay
    })

    if (!this.playerRef
      || typeof this.playerRef === 'string'
      || typeof this.playerRef === 'function'
      || this.playerRef.current === null) return;

    const audioElement = this.playerRef.current.audio.current;
    if (audioElement === null) return;

    if (audioElement.paused && !currentIsPlay) {
      audioElement.play();
      service.events(events.CLICK_AUDIO_BUTTON_PLAY);
    } else if (!audioElement.paused && currentIsPlay) {
      audioElement.pause();
      service.events(events.CLICK_AUDIO_BUTTON_PAUSE);
    }
  }

  render() {
    const { src } = this.props;
    const { isPlay } = this.state;

    return (
      <div>
        <Button onClick={this.openPLayer} isOpen={isPlay} aria-label="Включить аудио">
          {isPlay
            ? (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C6.47967 21.994 2.00606 17.5204 2 12V11.8C2.10993 6.30455 6.63459 1.92797 12.1307 2.0009C17.6268 2.07382 22.0337 6.5689 21.9978 12.0654C21.9619 17.5618 17.4966 21.9989 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57931 16.4087 4.00002 11.992 4.00002C7.57528 4.00002 3.99421 7.57931 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM15 16H13V8.00002H15V16ZM11 16H9V8.00002H11V16Z"/>
              </svg>
            )
            : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM4 12.172C4.04732 16.5732 7.64111 20.1095 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.68451 16.444 4.10977 12.0425 4.086C7.64111 4.06246 4.04732 7.59876 4 12V12.172ZM10 16.5V7.5L16 12L10 16.5Z" fill="#2E3A59"/>
              </svg>
            )}
        </Button>

        <Container isOpen={isPlay}>
          <AudioPlayer
            ref={this.playerRef}
            src={`/audio/${src}`}
            layout="horizontal-reverse"
            showJumpControls={false}
            customVolumeControls={[]}
            customAdditionalControls={[]}
          />
        </Container>
      </div>
    );
  }
}
