import React from 'react';
import { Button, Container, Message } from './style';
import { service } from '../../services/events.service';
import events from '../../constants/events';

type Props = {
  pageId: string;
}

type State = {
  liked: boolean;
  timer: boolean;
}

export class LikeButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      liked: !!localStorage.getItem(`like-${props.pageId}`) || false,
      timer: false
    }
  }

  handleClick = () => {
    const { pageId } = this.props;
    const { liked } = this.state;

    if (!liked) {
      this.setState({
        liked: true,
      });
      localStorage.setItem(`like-${pageId}`, String(true));
      service.events(events.CLICK_LIKE_BUTTON);
      setTimeout(() => {
        this.setState({
          timer: true
        });
      }, 3000)
    }
  }

  render() {
    const { liked, timer } = this.state;

    return (
      <Container>
        <Message isOpen={liked && !timer} >автору будет приятно...</Message>
        <Button onClick={this.handleClick} isOpen={liked} aria-label="Включить аудио">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 8.40001C1.99975 6.95035 2.58239 5.56146 3.61681 4.54584C4.65124 3.53022 6.05058 2.97317 7.5 3.00001C9.21732 2.99089 10.856 3.71919 12 5.00001C13.144 3.71919 14.7827 2.99089 16.5 3.00001C17.9494 2.97317 19.3488 3.53022 20.3832 4.54584C21.4176 5.56146 22.0002 6.95035 22 8.40001C22 13.756 15.621 17.8 12 21C8.387 17.773 2 13.76 2 8.40001Z"/>
          </svg>
        </Button>
      </Container>
    );
  }
}
