import React, { Fragment } from 'react';
import { HashRouter, Route, Switch, Link } from 'react-router-dom';
import './App.css';
import { Tutorial } from './components/NFCButton/view';
import { Page } from './pages/Page/view';
import { createHashHistory } from "history";
import { QRReader } from './components/QRReader/view';
import { CardsList } from './components/CardsList/view';
import { CardsSwiper } from './components/CardsSwiper/view';
import { service } from './services/events.service';
import initAnalytics from './utils/initAnalytics';
import events from './constants/events';

const customHistory = createHashHistory();

function App() {
  initAnalytics();
  service.events(events.START);

  return (
    <HashRouter>
      <div className="App">
        <header className="App-header">
          <Link className="App-logo" to="/">Каскад</Link>
          <QRReader history={customHistory}/>
          <Tutorial history={customHistory}/>
        </header>

        <main className="App-main">
          <div>
            <Switch>
              <Route path="/" exact>
                <Fragment>
                  <CardsSwiper history={customHistory}/>
                  <CardsList history={customHistory}/>
                </Fragment>
              </Route>
              <Route path="/projects/:id" render={(props) => <Page {...props}/>} />
            </Switch>
          </div>
        </main>

        <footer className="App-footer">
          <p><a href="https://kaskadproject.com" className="App-link">каскад</a></p>
          <a href="https://mosmuseum.ru/exhibitions/p/kaskad/">
            <img src="/img/mm.jpg" alt="Музей Москвы"/>
          </a>
          <p>dev by <a href="https://frolova.in" className="App-link">frolova.in</a></p>
        </footer>
      </div>
    </HashRouter>
  );
}

export default App;
