import React from 'react';
import { Card, List, Title, Icon } from './style';
import { data } from '../../data/config';
import { service } from '../../services/events.service';
import events from '../../constants/events';

type Props = {
  history: any;
}

export class CardsList extends React.Component<Props> {
  openCard = (link: string) => {
    service.events(events.CLICK_DETAILS_CARD);
    this.props.history.push(link);
  }

  render() {
    return (
      <List className="grid">
        {data.map(item => (
          <Card
            key={item.id}
            onClick={() => this.openCard(`/projects/${item.id}`)}
            backgroundImage={item.poster.name}
          >
            <Title>{item.title}</Title>
            {item.audio && (
              <Icon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 472.615 472.615" width="472.615" height="472.615"><path
                  d="M440.099 284.845v-48.49c0-112.388-91.404-203.792-203.792-203.792S32.515 123.967 32.515 236.355v48.585C12.95 298.362 0 320.859 0 346.38c0 38.376 29.208 70.042 66.45 74.012 3.686 11.343 14.368 19.661 26.939 19.661h14.273c15.596 0 28.357-12.761 28.357-28.357V281.159c0-15.596-12.761-28.357-28.357-28.357H93.389c-12.571 0-23.253 8.224-26.845 19.566-5.199.473-10.303 1.607-15.124 3.214v-39.227c0-101.99 82.991-184.887 184.887-184.887 101.99 0 184.887 82.897 184.887 184.887v39.227c-4.821-1.607-9.83-2.741-15.029-3.214-3.686-11.343-14.367-19.566-26.939-19.566h-14.178c-15.691 0-28.357 12.761-28.357 28.357v130.536c0 15.596 12.666 28.357 28.357 28.357h14.178c12.666 0 23.347-8.318 27.034-19.661 37.337-4.064 66.355-35.635 66.355-74.012 0-25.52-12.949-48.111-32.516-61.534z"/></svg>
              </Icon>
            )}
          </Card>
        ))}
      </List>
    );
  }
}
