import { service } from '../services/events.service';
import { v4 as uuidv4 } from 'uuid';

export default () => {
  const nowDate = String(new Date().toISOString().split('T')[0]);
  if (!localStorage.getItem('x-user') || localStorage.getItem('x-user-date') !== nowDate) {
    const id = uuidv4();
    localStorage.setItem('x-user', id);
    localStorage.setItem('x-user-date', nowDate);

    service.init(id);
  }
}
