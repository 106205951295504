import styled from 'styled-components';

type Prop = {
  isOpen?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 70px;
  width: 100%;
  min-height: 100vh;
  background-color: var(--dark-bg-color);
  z-index: 10;
`;

export const Title = styled.h2`
  font-size: 28px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  text-transform: lowercase;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 20%;
    display: block;
    width: 60%;
    height: 2px;
    background-color: var(--main-theme-red-color);
    animation: qr-line 5s ease infinite;
  }

  @keyframes qr-line {
    0% {
      top: 0;
    }

    28% {
      top: 28px;
    }

    57% {
      top: 14px;
    }

    75% {
      top: 24px;
    }

    100% {
      top: 0;
    }
  }
`

export const Button = styled.button<Prop>`
  background-color: ${(props) => props.isOpen ? 'transparent' : 'var(--light-bg-color)'};
  color: #2a2a2a;
  padding: 5px;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;

  svg {
    ${(props) => props.isOpen && 'fill: #fff;'};
  }
`
