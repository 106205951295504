import React from 'react';
import Swiper from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import { Button, Card, List, Title, Description } from './style';
import { data } from '../../data/config';
import { service } from '../../services/events.service';
import events from '../../constants/events';

SwiperCore.use([Autoplay]);
type Props = {
  history: any;
}

export class CardsSwiper extends React.Component<Props> {
  componentDidMount() {
    const swiper = new Swiper('.swiper-container', {
      init: false,
      slidesPerView: 'auto',
      loop: true,
      spaceBetween: 10,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      }
    });
    swiper.init();
  }

  openCard = (link: string) => {
    service.events(events.CLICK_DETAILS_SWIPER);
    this.props.history.push(link);
  }

  render() {
    return (
      <List className="swiper-container">
        <div className="swiper-wrapper">
          {data.map((item) => (
            <div key={item.id + item.title} className="swiper-slide">
              <Card backgroundImage={item.poster.name}>
                <Title>{item.title}</Title>
                <Description>{item.subtitle}</Description>
                <Button onClick={() => this.openCard(`/projects/${item.id}`)}>Подробнее..</Button>
              </Card>
            </div>
          ))}
        </div>
      </List>
    );
  }
}
