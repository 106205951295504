import React from 'react';
import { Button, Title, Container, ShadowBox, Message, ArrowLeft } from './style';
import { service } from '../../services/events.service';
import events from '../../constants/events';

type Props = {
  history: any;
}

type State = {
  message: string;
  recordType: string;
  data: any;
  stepNumber: number;
}

export class Tutorial extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      stepNumber: Number(localStorage.getItem('stepNumber')),
      message: 'null',
      recordType: 'null',
      data: undefined,
    }
  }

  componentDidMount() {
    const { stepNumber } = this.state;
    if (stepNumber === 0) {
      localStorage.setItem('stepNumber', String(this.state.stepNumber + 1));
      this.setState({
        stepNumber: this.state.stepNumber + 1
      });
    }
  }

  selectNFC = (e: React.MouseEvent) => {
    service.events(events.CLICK_ACCEPT_TUTORIAL);
    localStorage.setItem('stepNumber', String(this.state.stepNumber + 1));
    this.setState({
      stepNumber: this.state.stepNumber + 1
    });

    (e.target as HTMLElement).onclick = async () => {
      try {
        const ndef = new NDEFReader();

        await ndef.scan().then(() => {
          this.setState({
            message: 'Scan started successfully.',
          });

          ndef.onreadingerror = () => {
            this.setState({
              message: 'Cannot read data from the NFC tag. Try another one?'
            });

            service.events(events.SCAN_NFC_ERROR);
          };

          ndef.onreading = event => {
            const message = event.message;

            for (const record of message.records) {
              console.log("Record type:  " + record.recordType);
              console.log("MIME type:    " + record.mediaType);
              console.log("Record id:    " + record.id);
              switch (record.recordType) {
                case "text": {
                  const textDecoder = new TextDecoder();
                  const url = textDecoder.decode(record.data);
                  this.setState({
                    message: 'NDEF message read.',
                    recordType: record.recordType,
                    data: url
                  });
                  service.events(events.SCAN_NFC_READ);
                  this.props.history.push(`/projects/${url}`);
                  break;
                }
                case "url": {
                  break;
                }
                default:
                  break;
              }
            }
          };
        }).catch(error => {
          service.events(events.SCAN_NFC_FAILED);
          this.setState({
            message: `Error! Scan failed to start: ${error}.`
          });
        });
      } catch (e) {
        return;
      }
    };
  }

  render() {
    const { stepNumber } = this.state;
    return (
      <Container isOpen={stepNumber < 3}>
        <ShadowBox stepNumber={stepNumber}/>
        <Title className="title">
          {stepNumber === 1
            ? (
              <Message>
                <ArrowLeft>
                  <svg xmlns="http://www.w3.org/2000/svg" width="171.48" height="213.678" viewBox="0 0 171.48 213.678">
                    <g>
                      <path fill="none" strokeWidth="2" strokeLinejoin="round"
                            d="M166.186 206.263L7.122 9.187M9.772 41.749L5.952 6.848l63.158 42.69"/>
                    </g>
                  </svg>
                </ArrowLeft>
                <p>Это <span>каскад</span></p>
              </Message>
            )
            : stepNumber === 2
              ? (
                <Message>
                  <ArrowLeft invert>
                    <svg xmlns="http://www.w3.org/2000/svg" width="171.48" height="213.678" viewBox="0 0 171.48 213.678">
                      <g>
                        <path fill="none" strokeWidth="2" strokeLinejoin="round"
                              d="M166.186 206.263L7.122 9.187M9.772 41.749L5.952 6.848l63.158 42.69"/>
                      </g>
                    </svg>
                  </ArrowLeft>
                  <p>Эта кнопка поможет сканировать QR</p>
                </Message>
              )
              : ''
          }
        </Title>
        <Button className="button" onClick={this.selectNFC}>Понятно!</Button>
      </Container>
    );
  }
}
