import { client } from './axios';

class EventsService {
  public init = async (userId: string) =>
    client
      .post('/users', { userId, platform: window.navigator.userAgent })
      .then()
      .catch((err) => {
        // throw err.response.status;
        console.log(err.response.status);
      });

  public events = async (type: string) =>
    client
      .post('/events', { type, userId: localStorage.getItem('x-user') || 'removed' })
      .then()
      .catch((err) => {
        // throw err.response.status;
        console.log(err.response.status);
      });

  public users = async () =>
    client
      .get('/users')
      .then(
        (response) => {
          return response;
        }
      )
      .catch((err) => {
        // throw err.response.status;
        console.log(err.response.status);
      });
}

export const service: EventsService = new EventsService();
