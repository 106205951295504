import styled from 'styled-components';

type Props = {
  backgroundImage?: string;
}

export const List = styled.div`
  padding: 14px;
  height: 130vw;

  .swiper-slide {
    width: calc(100vw - 24px - 15vw);
  }

   .swiper-slide-active div {
      box-shadow: var(--neon-theme-yellow-shadow);
      border: var(--neon-theme-yellow-color) 3px solid;
    }
`;

export const Card = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 120vw;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(var(--dark-bg-color-rgb),0.7), rgba(var(--dark-bg-color-rgb),1)), url('/img/projects/${(props) => props.backgroundImage}');
  transform: scaleY(1);
  transition: transform 0.2s ease;

  .swiper-slide:not(.swiper-slide-active) & {
    transform: scaleY(0.9);
  }
`

export const Title = styled.p`
  font-weight: 700;
  margin: 0 0 6px;
`

export const Description = styled.p`
  margin: 0 0 20px;
`

export const Button = styled.button`
  font-weight: 700;
  color: var(--main-text-color);
  font-size: 16px;
  border-radius: 12px;
  padding: 14px;
  border: var(--neon-theme-powderblue-color) 3px solid;
  background-color: transparent;
  box-shadow: inset var(--neon-theme-powderblue-shadow);
`
